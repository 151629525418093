<template>
  <div id="reservation">
    <van-nav-bar title="交易成功订单" left-arrow @click-left="goLastPage" :border="false" />
    <div class="reservationWrap">
      <span class="tip" v-if="reservationOrderList.length == 0? true: false" >你还没有交易成功订单哦</span>
      <span class="tip" v-else>师傅继续努力吧</span>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <OrderListPublic v-for="(item,i) in reservationOrderList" :key="i" :listPublicObj="item" :toPageId="item.id" toPageName="success_detail" />
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import OrderListPublic from "@/components/order/OrderListPublic.vue";
import TopBar from "@/components/home/TopBar.vue";
import { Toast } from "vant";

export default {
  components: {
    OrderListPublic,
    TopBar,
  },
  data() {
    return {
      reservationOrderList: [],
      isLoading: true,
      reservationID: 0,
    };
  },
  created() {
    this.getReservationList();
  },
  methods: {
    goLastPage() {
      this.$router.go(-1);
    },
    getReservationList() {
      this.$http
        .get("api/v2/worker/orders/service?work_status=17")
        .then((res) => {
          console.log("师傅交易成功订单", res);
          this.reservationOrderList = res.data.result.data;
        });
    },
    onRefresh() {
      this.getReservationList();
      setTimeout(() => {
        Toast("刷新成功");
        this.isLoading = false;
      }, 1000);
    },
  },
};
</script>
<style lang="less">
#reservation {
  .tip {
    margin-top: 45px;
    font-size: 1.4rem;
    padding: 1rem 0;
    text-align: center;
    margin: 1rem 1rem 0 1rem;
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
</style>